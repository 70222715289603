import {ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState} from 'react'
import {FilterModel} from '../../../models/FilterModel'
import {GlobalSearchModel, GroupedSearchModel} from '../../../models/GlobalSearchModel'
import PaginationHelper from '../../extras/PaginationHelper'
import {useBooleanState} from '../../hooks/useBooleanState'
import {useFilterState} from '../../hooks/useFilterState'
import {useOnChange} from '../../hooks/useOnChange'
import {useSafeStateUpdate} from '../../hooks/useSafeStateUpdate'
import {TextInput} from '../../inputs'
import {MetronicIconButton} from '../../inputs/MetronicIconButton'
import {INITIAL_TABLE_PAGE_SIZE} from '../FilterTable'
import {GroupedTableData, Table, TableProps} from '../Table'
import {TableColumnOptions} from '../TableColumn'
import {TableSelectionCountLabel} from '../TableSelectionCountLabel'
import {TableFilterStateContext} from '../useTableFilterState'
import {PopupFilterButton} from './PopupFilterButton/PopupFilterButton'
import {Button} from '../../inputs/Button'
import {LoadingSpinner} from '../../utils/LoadingSpinner'
import {MetronicIcon} from '../../inputs/MetronicIcon'

type FilterTableTableProps<T> = Pick<
  TableProps<T>,
  | 'selection'
  | 'onSelectionChange'
  | 'rightToolbar'
  | 'idExtractor'
  | 'actions'
  | 'hiddenColumns'
  | 'onHiddenColumnsChange'
  | 'title'
  | 'hideSelectAll'
  | 'actions'
  | 'expandedGroups'
  | 'onExpandedGroupsChange'
  | 'grouping'
  | 'classes'
>

export type ControlledFilterTableColumnOptions<T> = TableColumnOptions<T>

export interface ControlledFilterTableProps<T> extends FilterTableTableProps<T> {
  onFilter: (filter: FilterModel) => void
  searchResults?: GlobalSearchModel<T> | GroupedSearchModel<T>
  filters: FilterModel
  noPagination?: boolean
  selectionAction?: ReactNode
  leftToolbar?: ReactNode
  columns: ControlledFilterTableColumnOptions<T>[]
  className?: string
  initialFilters?: FilterModel
  advancedFilters?: ReactNode
  filterOnMount?: boolean
  loading?: boolean
  isTrp?: boolean
}

export const ControlledFilterTable = <T,>({
  onFilter,
  noPagination,
  leftToolbar,
  selectionAction,
  selection,
  onSelectionChange,
  columns,
  rightToolbar,
  className,
  initialFilters,
  advancedFilters: advancedFiltersNode,
  searchResults,
  filters,
  filterOnMount,
  onExpandedGroupsChange,
  expandedGroups,
  hiddenColumns,
  loading = false,
  isTrp = false,
  ...tableProps
}: ControlledFilterTableProps<T>) => {
  const [tempCode, setTempCode] = useState('')
  const [tempEmail, setTempEmail] = useState('')
  const [tempName, setTempName] = useState('')
  const safeUpdate = useSafeStateUpdate()
  const {
    state: isPopupOpen,
    enableState: openPopup,
    disableState: closePopup,
  } = useBooleanState(false)

  useOnChange(searchResults?.data, () => {
    safeUpdate(() => onSelectionChange && onSelectionChange([]))
  })

  const filterState = useFilterState(onFilter, {initialFilters, filters, filterOnMount})

  const {
    sortColumn,
    setSortColumn,
    pageNumber,
    setPageSize,
    setPageNumber,
    setSearch,
    search,
    clearFilters,
    hasFilters,
    // setManualSearch,
  } = filterState

  const handleManualSearch = useCallback(() => {
    // setManualSearch(tempCode, tempEmail)
    const shouldResetPage =
      tempCode !== filters.filters?.code ||
      tempEmail !== filters.filters?.customerEmail ||
      tempName !== filters.filters?.customerName

    const updatedFilters = {
      ...filters,
      filters: {
        ...filters.filters,
        code: tempCode,
        customerEmail: tempEmail,
        search: '',
        customerName: tempName,
      },
      page: shouldResetPage ? 1 : filters.page,
    }

    onFilter(updatedFilters)
  }, [tempCode, tempEmail, tempName, onFilter, filters])

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
    },
    [setSearch]
  )

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        handleManualSearch()
      }
    },
    [handleManualSearch]
  )

  const handleSearchCode = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTempCode(e.target.value)
      setSearch(e.target.value)
    },
    [setSearch]
  )

  const handleSearchEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTempEmail(e.target.value)
    },
    [setTempEmail]
  )

  const handleSearchName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTempName(e.target.value)
    },
    [setTempName]
  )

  const resetSearch = useCallback(() => {
    setTempCode('')
    setTempEmail('')
    setTempName('')
    setPageNumber(1)

    // setManualSearch('', '')
    const clearedFilters = {
      ...filters,
      filters: {
        ...filters.filters,
        code: null,
        customerEmail: null,
        customerName: null,
        search: '',
      },
      page: 1,
    }

    onFilter(clearedFilters)
  }, [onFilter, filters, setPageNumber, setSearch])

  useEffect(() => {
    if (search) {
      setTempCode(search)
    }
  }, [search])

  const searchAction = useMemo(() => {
    if (isTrp) {
      return (
        <div className='w-100'>
          <div className='table-left-toolbar'>
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search Code'
              value={tempCode}
              onChange={handleSearchCode}
              onKeyDown={loading ? undefined : handleKeyPress}
            />

            {leftToolbar}
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search Name'
              value={tempName}
              onChange={handleSearchName}
              onKeyDown={loading ? undefined : handleKeyPress}
            />
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search Email'
              value={tempEmail}
              onChange={handleSearchEmail}
              onKeyDown={loading ? undefined : handleKeyPress}
            />
            {hasFilters && (
              <MetronicIconButton
                className='rs-clear-filter'
                iconType='Code'
                iconName='Stop'
                activeColor='danger'
                size='md'
                onClick={resetSearch}
                tooltip='Clear Filters'
              />
            )}
            <Button
              uppercase={false}
              variant='primary'
              onClick={handleManualSearch}
              disabled={(!tempCode && !tempEmail && !tempName) || loading}
            >
              <LoadingSpinner spinnerOnly loading={loading} />
              <MetronicIcon iconType='General' iconName='Magnifier' /> Search
            </Button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='w-100'>
          <div className='table-left-toolbar'>
            <TextInput
              className='filter-table-search-input'
              noMargin
              placeholder='Search'
              value={search}
              onChange={handleSearchChange}
            />
            {leftToolbar}
            {hasFilters && (
              <MetronicIconButton
                iconType='Code'
                iconName='Stop'
                activeColor='danger'
                size='md'
                onClick={clearFilters}
                tooltip='Clear Filters'
              />
            )}
          </div>
        </div>
      )
    }
  }, [
    search,
    handleSearchChange,
    leftToolbar,
    hasFilters,
    clearFilters,
    isTrp,
    handleSearchCode,
    handleKeyPress,
    resetSearch,
    handleManualSearch,
    handleSearchEmail,
    handleSearchName,
    loading,
    tempCode,
    tempEmail,
    tempName,
  ])

  const bodyActions = useMemo(() => {
    return (
      <div className='mt-3'>
        <TableSelectionCountLabel count={selection?.length} />
        {selectionAction && <div className='table-left-toolbar'>{selectionAction}</div>}
      </div>
    )
  }, [selection?.length, selectionAction])

  const hasAdvancedFiltersValue = useMemo(() => {
    return Boolean(filters?.filters && Object.entries(filters.filters).length)
  }, [filters.filters])

  const handleChangePageNumber = useCallback(
    (pageNumber: number) => {
      setPageNumber(pageNumber)
      onSelectionChange && onSelectionChange([])
    },
    [onSelectionChange, setPageNumber]
  )

  const filterButton = useMemo(() => {
    const hasAdvancedFilterInputs = Boolean(advancedFiltersNode)
    if (hasAdvancedFilterInputs) {
      return (
        <PopupFilterButton
          isOpen={isPopupOpen}
          onClose={closePopup}
          onOpen={openPopup}
          hasAdvancedFilters={hasAdvancedFiltersValue}
        >
          {advancedFiltersNode}
        </PopupFilterButton>
      )
    }
    return null
  }, [advancedFiltersNode, closePopup, hasAdvancedFiltersValue, isPopupOpen, openPopup])

  const groupedTableData = useMemo(() => {
    if (searchResults) {
      if (!Array.isArray(searchResults.data)) {
        const groupBy = filters?.groupBy
        if (groupBy) {
          return Object.entries(searchResults.data).map<GroupedTableData<T>>(([key, value]) => ({
            key,
            data: value,
            field: groupBy,
            label: key,
          }))
        }
      }
    }
  }, [filters?.groupBy, searchResults])

  const tableData = useMemo(() => {
    if (Array.isArray(searchResults?.data)) {
      return searchResults?.data
    }
  }, [searchResults?.data])

  useOnChange(searchResults, (previousValue) => {
    if (previousValue?.page !== searchResults?.page || !previousValue) {
      if (searchResults && !Array.isArray(searchResults.data)) {
        const groupKeys = Object.keys(searchResults.data)
        const firstGroupKey = Object.keys(searchResults.data)[0]
        if (
          firstGroupKey &&
          expandedGroups &&
          !groupKeys.some((key) => expandedGroups?.includes(key))
        ) {
          onExpandedGroupsChange?.([firstGroupKey])
        }
      }
    }
  })

  return (
    <TableFilterStateContext.Provider value={filterState}>
      <div className={className}>
        <Table
          {...tableProps}
          expandedGroups={expandedGroups}
          onExpandedGroupsChange={onExpandedGroupsChange}
          columns={columns}
          data={tableData}
          groupedData={groupedTableData}
          onSelectionChange={onSelectionChange}
          selection={selection}
          leftToolbar={searchAction}
          sortedColumn={sortColumn}
          onSort={setSortColumn}
          body={bodyActions}
          grouping={filters?.groupBy}
          hiddenColumns={hiddenColumns}
          rightToolbar={
            <>
              {rightToolbar}
              {filterButton}
            </>
          }
        />
        {!noPagination && (
          <PaginationHelper
            currentPageNumber={searchResults?.page || pageNumber}
            currentPageSize={searchResults?.limit || INITIAL_TABLE_PAGE_SIZE}
            onChangePageNumber={handleChangePageNumber}
            onChangePageSize={setPageSize}
            total={searchResults?.total}
          />
        )}
      </div>
    </TableFilterStateContext.Provider>
  )
}
