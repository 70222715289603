import draftToHtml from 'draftjs-to-html'
import {useMemo} from 'react'

export interface DraftJsRendererProps {
  children?: string | null
}

export const DraftJsRenderer = ({children}: DraftJsRendererProps) => {
  const html = useMemo(() => {
    if (children) {
      try {
        return draftToHtml(JSON.parse(children), undefined, undefined, (entity, text) => {
          if (entity.type === 'IMAGE') {
            return `<img style="max-width: 100%; height: ${
              entity.data.height ? `${entity.data.height}px` : 'auto'
            }; width: ${entity.data.width ? `${entity.data.width}px` : 'auto'};" src="${
              entity.data.src
            }" alt="image" />`
          } else if (entity.type === 'LINK') {
            let url = entity.data.url
            if (!/^https?:\/\//i.test(url)) {
              url = `https://${url}`
            }
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`
          }
          return text
        })
      } catch (e) {
        return null
      }
    }
    return null
  }, [children])

  if (html) {
    return <div dangerouslySetInnerHTML={{__html: html}}></div>
  }
  return null
}
