import React, {Component, createRef} from 'react'
import {composeDecorators} from '@draft-js-plugins/editor'
import {
  EditorState,
  RichUtils,
  convertToRaw,
  RawDraftContentState,
  convertFromRaw,
  KeyBindingUtil,
  getDefaultKeyBinding,
  DraftBlockRenderConfig,
  DefaultDraftBlockRenderMap,
  Editor,
  ContentBlock,
  Modifier,
  SelectionState,
  DraftHandleValue,
  CompositeDecorator,
} from 'draft-js'
import Immutable, {Map} from 'immutable'
import createImagePlugin from '@draft-js-plugins/image'
import createAlignmentPlugin from '@draft-js-plugins/alignment'
import createFocusPlugin from '@draft-js-plugins/focus'
import createResizeablePlugin from '@draft-js-plugins/resizeable'
import createBlockDndPlugin from '@draft-js-plugins/drag-n-drop'
import clsx from 'clsx'
import {draftToMarkdown} from 'markdown-draft-js'
import '@draft-js-plugins/image/lib/plugin.css'
import '@draft-js-plugins/alignment/lib/plugin.css'
import styles from './RichTextEditor.module.scss'
import {RichTextEditorControlButton} from './RichTextEditorControlButton'
import {MetronicIcon} from '../MetronicIcon'
import {BaseFileInputValue} from '../FileInput/BaseFileInputValue'
import {
  getBlockRendererFn,
  handleKeypressWhenSelectionNotCollapsed,
  insertTable,
} from './utlis/renderUtils'
import TableGrid from './utlis/tableGrid'
import detectIndent from 'detect-indent'
import {detectNewline} from 'detect-newline'
import {LinkModal} from './utlis/LinkModal'

const Link = (props: any) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData()
  return (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      {props.children}
    </a>
  )
}

const linkDecorator = {
  strategy: (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: any
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity()
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
  },
  component: Link,
}

export const TABLE_BLOCK_TYPE = 'table'
export const DEFAULT_INDENTATION = '    '

export type RichTextEditorValue = EditorState

export const alignmentStyles = ['left', 'right', 'center']

export interface RichTextEditorProps {
  value: RichTextEditorValue | null
  onChange: (value: RichTextEditorValue) => void
  onUpload?: () => void
  className?: string
  classes?: {
    textArea?: string
  }
  hideControls?: boolean
  readOnly?: boolean
  dir?: 'rtl' | 'ltr' | 'auto'
}

interface RichTextEditorState {
  isFileListOpen: boolean
  isCursorOutsideTheEditor: boolean
  modal: {
    show: boolean
  }
  linkModal: {
    open: boolean
    defaultUrl: string
  }
}

interface ImageEntity {
  data: {
    src: string
    width: number
    height: number
  }
}

export class RichTextEditor extends Component<RichTextEditorProps, RichTextEditorState> {
  private emptyState = RichTextEditor.createEmptyState()
  private editorRef = createRef<Editor>()
  constructor(props: RichTextEditorProps) {
    super(props)

    this.state = {
      isFileListOpen: false,
      isCursorOutsideTheEditor: false,
      modal: {
        show: false,
      },
      linkModal: {open: false, defaultUrl: ''},
    }
  }

  public static createEmptyState = () => {
    const decorator = new CompositeDecorator([linkDecorator])
    return EditorState.createEmpty(decorator)
  }

  public static hasText = (value: EditorState) => {
    return value.getCurrentContent().hasText()
  }

  public static getEditorStateFromJSONString(value: string) {
    try {
      return EditorState.createWithContent(convertFromRaw(JSON.parse(value)))
    } catch (e) {
      return null
    }
  }

  public static getEditorStateFromRaw(value: RawDraftContentState) {
    return EditorState.createWithContent(convertFromRaw(value))
  }

  public static getRawValueFromEditorState(value: EditorState) {
    return convertToRaw(value.getCurrentContent())
  }

  public hasText = () => {
    return RichTextEditor.hasText(this.getValue())
  }

  public addImage = (url: string) => {
    this.props.onChange(imagePlugin.addImage(this.getValue(), url, {}))
  }

  public editLink = () => {
    let editorState = this.getValue()
    let selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const linkData = this.getLinkEntityAtSelection(editorState)
    let defaultUrl = ''

    if (linkData) {
      defaultUrl = linkData.url
      if (selection.isCollapsed()) {
        const block = contentState.getBlockForKey(selection.getAnchorKey())
        const linkRange = this.getLinkRangeInBlock(block, linkData.entityKey)
        if (linkRange) {
          selection = selection.merge({
            anchorOffset: linkRange.start,
            focusOffset: linkRange.end,
          })
          editorState = EditorState.forceSelection(editorState, selection)
        }
      }
    }

    this.setState({linkModal: {open: true, defaultUrl}})
  }

  private getLinkEntityAtSelection = (
    editorState: EditorState
  ): {entityKey: string; url: string} | null => {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const block = contentState.getBlockForKey(selection.getAnchorKey())
    const offset = selection.getAnchorOffset()
    let entityKey = block.getEntityAt(offset)
    if (!entityKey && offset > 0) {
      entityKey = block.getEntityAt(offset - 1)
    }
    if (entityKey) {
      const entity = contentState.getEntity(entityKey)
      if (entity.getType() === 'LINK') {
        const {url} = entity.getData()
        return {entityKey, url}
      }
    }
    return null
  }

  private getLinkRangeInBlock = (
    block: ContentBlock,
    entityKey: string
  ): {start: number; end: number} | null => {
    let start = -1
    let end = -1
    block.findEntityRanges(
      (character) => character.getEntity() === entityKey,
      (startIdx, endIdx) => {
        start = startIdx
        end = endIdx
      }
    )
    return start >= 0 && end >= 0 ? {start, end} : null
  }

  public getMarkdown = (): string => {
    const raw = convertToRaw(this.getValue().getCurrentContent())
    return draftToMarkdown(raw, {
      styleItems: {
        UNDERLINE: {
          open: () => '<ins>',
          close: () => '</ins>',
        },
      },
      entityItems: {
        IMAGE: {
          open: (entity) => '',
          close: (entity) => {
            const imageEntity = entity as ImageEntity
            const width = imageEntity.data.width ? `width="${imageEntity.data.width}"` : ''
            const height = imageEntity.data.height ? `height="${imageEntity.data.height}"` : ''
            return `<img src="${imageEntity.data.src}" ${width} ${height}>`
          },
        },
      },
    })
  }

  public getRawValue = (): RawDraftContentState => {
    return RichTextEditor.getRawValueFromEditorState(this.getValue())
  }

  public setValueFromRaw = (value: RawDraftContentState) => {
    return this.props.onChange(RichTextEditor.getEditorStateFromRaw(value))
  }

  private ensureLinkDecorator = (editorState: EditorState) => {
    if (!editorState.getDecorator()) {
      return EditorState.set(editorState, {decorator: new CompositeDecorator([linkDecorator])})
    }
    return editorState
  }

  private getValue = () => {
    const state = this.props.value !== null ? this.props.value : this.emptyState
    return this.ensureLinkDecorator(state)
  }

  private toggleBlockStyle = (style?: string) => {
    if (style) {
      this.props.onChange(RichUtils.toggleBlockType(this.getValue(), style))
    }
  }

  private toggleInlineStyle = (style?: string) => {
    if (style) {
      this.props.onChange(RichUtils.toggleInlineStyle(this.getValue(), style))
    }
  }

  private isBlockStyleActive = (style: string) => {
    const editorState = this.getValue()
    const selection = editorState.getSelection()
    return (
      this.getValue().getCurrentContent().getBlockForKey(selection.getStartKey()).getType() ===
      style
    )
  }

  private isInlineStyleActive = (style: string) => {
    return this.getValue().getCurrentInlineStyle().has(style)
  }

  private showFileList = () => {
    if (this.props.onUpload) {
      this.props.onUpload()
    } else {
      this.setState({
        isFileListOpen: true,
      })
    }
  }

  private hideFileList = () => {
    this.setState({
      isFileListOpen: false,
    })
  }

  private insertFileImage = (file: BaseFileInputValue) => {
    this.addImage(file.url)
    this.hideFileList()
  }

  private handleMouseDown = () => {
    this.editorRef?.current?.focus()
  }

  private blockRenderMap: {[key: string]: DraftBlockRenderConfig} = {
    table: {
      element: 'div',
    },
  }

  private getIndentation = (text: string) => {
    const result = detectIndent(text)
    return result.indent || DEFAULT_INDENTATION
  }

  private getNewLine = (text: string) => {
    return detectNewline(text) || `\n`
  }

  private getLines(text: string, sep?: string) {
    sep = sep || this.getNewLine(text)
    return Immutable.List(text.split(sep))
  }

  private customBlockRenderMap = Map(this.blockRenderMap)
  private extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(this.customBlockRenderMap)

  private removeSizeDataFromBlock = (newEditorState: EditorState, block: ContentBlock) => {
    const data = block.getData().delete('height').delete('width')
    block = block.set('data', data) as any
    let contentState = newEditorState.getCurrentContent()
    let blockMap = contentState.getBlockMap()
    blockMap = blockMap.set(block.getKey(), block)
    contentState = contentState.set('blockMap', blockMap) as any
    newEditorState = EditorState.push(newEditorState, contentState, 'change-block-data')
    return newEditorState
  }

  private handleTabInTable = (direction: string = 'next', collapsed: boolean = false) => {
    const editorState = this.getValue()
    let newEditorState = editorState
    let selection = editorState.getSelection()
    let contentState = editorState.getCurrentContent()
    let targetKey = selection.getAnchorKey()
    let targetBlock = contentState.getBlockForKey(targetKey)
    do {
      if (direction === 'next') {
        targetBlock = contentState.getBlockAfter(targetKey) as any
      } else {
        targetBlock = contentState.getBlockBefore(targetKey) as any
      }
      targetKey = targetBlock && targetBlock.getKey()
    } while (targetKey && ['atomic', 'horizontal-rule'].includes(targetBlock.getType()))
    if (!targetBlock && direction === 'next') {
      selection = selection.merge({
        anchorOffset: contentState.getBlockForKey(selection.getAnchorKey()).getLength(),
        focusOffset: contentState.getBlockForKey(selection.getAnchorKey()).getLength(),
      })
      contentState = Modifier.splitBlock(contentState, selection)
      targetBlock = contentState.getLastBlock()
      selection = SelectionState.createEmpty(targetBlock.getKey())
      contentState = Modifier.setBlockType(contentState, selection, 'unstyled')
      targetBlock = contentState.getLastBlock()
      newEditorState = EditorState.push(editorState, contentState, 'split-block')
    } else if (!targetBlock) {
      targetBlock = contentState.getBlockForKey(selection.getAnchorKey())
    }
    const isTargetTable = targetBlock.getType() === 'table' && !collapsed
    const endOffset = targetBlock.getLength()
    selection = SelectionState.createEmpty(targetBlock.getKey())
    selection = selection.merge({
      anchorOffset: isTargetTable || direction === 'next' ? 0 : endOffset,
      focusOffset: isTargetTable || direction === 'previous' ? endOffset : 0,
    })
    this.props.onChange(EditorState.forceSelection(newEditorState, selection))
  }

  private handleBeforeInput = (chars: string, newEditorState: EditorState): DraftHandleValue => {
    const selection = newEditorState.getSelection()
    if (!selection.isCollapsed()) {
      return handleKeypressWhenSelectionNotCollapsed(newEditorState, chars, (state) =>
        this.props.onChange(state)
      )
    }
    return 'not-handled'
  }

  private handleKeyCommand = (command: string, newEditorState: EditorState) => {
    switch (command) {
      case 'backspace': {
        // removing images ("atomic" blocktype) with backspace requires special handling or the image tag and dataUrl can be left in the content but not visible.
        let contentState = newEditorState.getCurrentContent()
        let selectionState = newEditorState.getSelection()
        const startKey = selectionState.getStartKey()
        const offset = selectionState.getStartOffset()
        const collapsed = selectionState.isCollapsed()
        const blockBefore = contentState.getBlockBefore(startKey)
        const currentBlockType = RichUtils.getCurrentBlockType(newEditorState)

        const currentBlock = contentState.getBlockForKey(startKey)
        const blockText = currentBlock.getText()
        const newLine = this.getNewLine(blockText)
        const indent = this.getIndentation(blockText)
        const lines = this.getLines(blockText, newLine)
        const lineToArray = lines.toArray()
        const beforeSelection = lineToArray[0].slice(0, offset)

        if (collapsed && offset === 0 && blockBefore && blockBefore.getType() === 'atomic') {
          newEditorState = this.removeSizeDataFromBlock(newEditorState, blockBefore)
          newEditorState = EditorState.acceptSelection(newEditorState, selectionState)
          const newState = RichUtils.onBackspace(newEditorState)
          if (newState) this.props.onChange(newState)
          return 'handled'
        } else if (currentBlockType === 'atomic') {

          newEditorState = this.removeSizeDataFromBlock(newEditorState, currentBlock)
          newEditorState = EditorState.acceptSelection(newEditorState, selectionState)
          const newState = RichUtils.onBackspace(newEditorState)
          if (newState) newEditorState = newState
          contentState = newEditorState.getCurrentContent()
          selectionState = newEditorState.getSelection()
          const key = selectionState.getAnchorKey()
          let selection = SelectionState.createEmpty(key)
          selection = selection.set('focusOffset', 1) as any
          contentState = Modifier.removeRange(contentState, selection, 'backward')
          this.props.onChange(EditorState.push(newEditorState, contentState, 'backspace-character'))
          return 'handled'
        } else if (currentBlockType === 'table' && collapsed && offset === 0) {
          return 'handled'
        } else if (
          currentBlockType !== 'table' &&
          collapsed &&
          blockBefore?.getType() === 'table' &&
          offset === 0
        ) {
          this.handleTabInTable('previous', true)
          return 'handled'
        } else if (collapsed && offset === 0 && blockBefore?.getType() === 'page-break') {
          let selection = SelectionState.createEmpty(blockBefore.getKey())
          contentState = Modifier.setBlockData(contentState, selection, Map({}))
          contentState = Modifier.setBlockType(contentState, selection, 'unstyled')
          selection = selection.merge({
            focusKey: selectionState.getFocusKey(),
            focusOffset: 0,
            hasFocus: true,
          })
          contentState = Modifier.removeRange(contentState, selection, 'backward')
          this.props.onChange(EditorState.push(newEditorState, contentState, 'remove-range'))
          return 'handled'
        } else if (currentBlockType === 'page-break') {
          contentState = Modifier.setBlockData(contentState, selectionState, Map({}))
          contentState = Modifier.setBlockType(contentState, selectionState, 'unstyled')
          let selection = selectionState
          if (collapsed && contentState.getBlockAfter(startKey)) {
            selection = selection.merge({
              focusKey: contentState?.getBlockAfter(startKey)?.getKey(),
              focusOffset: 0,
              hasFocus: true,
            })
          }
          contentState = Modifier.removeRange(contentState, selection, 'backward')
          this.props.onChange(EditorState.push(newEditorState, contentState, 'remove-range'))
          return 'handled'
        } else if (
          collapsed &&
          offset === 0 &&
          ['pasted-list-item', 'ordered-list-item', 'unordered-list-item'].includes(
            currentBlockType
          )
        ) {
          contentState = Modifier.setBlockType(contentState, selectionState, 'unstyled')
          this.props.onChange(EditorState.push(newEditorState, contentState, 'change-block-type'))
          return 'handled'
        } else if (collapsed && beforeSelection.endsWith(DEFAULT_INDENTATION)) {
          const beforeIndentOffset = offset - indent.length
          const rangeToRemove = selectionState.merge({
            focusKey: startKey,
            focusOffset: beforeIndentOffset,
            anchorKey: startKey,
            anchorOffset: offset,
            isBackward: true,
          })

          const newContentState = Modifier.removeRange(contentState, rangeToRemove, 'backward')
          const newEditorState1 = EditorState.push(newEditorState, newContentState, 'remove-range')
          this.props.onChange(
            EditorState.forceSelection(newEditorState1, newContentState.getSelectionAfter())
          )
          return 'handled'
        } else {
          return 'not-handled'
        }
      }
      case 'shiftTab':
        return 'handled'
      case 'tab':
        let contentState = newEditorState.getCurrentContent()
        let selectionState = newEditorState.getSelection()
        const startKey = selectionState.getStartKey()
        const currentBlock = contentState.getBlockForKey(startKey)
        const indentation = this.getIndentation(currentBlock.getText())
        let newContentState = null
        if (selectionState.isCollapsed()) {
          newContentState = Modifier.insertText(contentState, selectionState, indentation)
        } else {
          newContentState = Modifier.replaceText(contentState, selectionState, indentation)
        }
        this.props.onChange(EditorState.push(newEditorState, newContentState, 'insert-characters'))
        return 'handled'
      default:
        return 'not-handled'
    }
  }

  // Custom key bindings (e.g., Shift + Enter to create a soft line break)
  private keyBindingFn = (e: React.KeyboardEvent): string | null => {
    if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.key === 'Enter') {
      return 'insert-soft-line-break'
    }
    if (e.key === 'Tab') {
      return 'tab'
    }
    return getDefaultKeyBinding(e)
  }

  private insertTableFn = ({rows, columns}: {rows: number; columns: number}) => {
    insertTable({rows, columns, editorState: this.getValue(), onChange: this.props.onChange})
    this.setState({...this.state, modal: {show: false}})
  }

  private blockRendererFn = (block: ContentBlock, editor: Editor | null) => {
    return getBlockRendererFn(editor, block, this.getValue(), this.props.onChange)
  }

  private blockStyleFn = (block: any) => {
    let alignment = 'left'
    block.findStyleRanges((e: any) => {
      if (e.hasStyle('center')) {
        alignment = 'center'
      }
      if (e.hasStyle('right')) {
        alignment = 'right'
      }
    })

    return `editor-alignment-${alignment}`
  }

  private applyAlignment = (newStyle: any) => {
    const editorState = this.getValue()
    let styleForRemove = alignmentStyles.filter((style) => style !== newStyle)
    let currentContent = editorState.getCurrentContent()
    let selection = editorState.getSelection()
    let focusBlock = currentContent.getBlockForKey(selection.getFocusKey())
    let anchorBlock = currentContent.getBlockForKey(selection.getAnchorKey())
    let isBackward = selection.getIsBackward()

    let selectionMerge = {
      anchorOffset: 0,
      focusOffset: focusBlock.getLength(),
    }
    
    if (isBackward) {
      selectionMerge.anchorOffset = anchorBlock.getLength()
    }
    let finalSelection = selection.merge(selectionMerge)
    let finalContent = styleForRemove.reduce(
      (content, style) => Modifier.removeInlineStyle(content, finalSelection, style),
      currentContent
    )
    let modifiedContent = Modifier.applyInlineStyle(finalContent, finalSelection, newStyle)
    
    this.props.onChange(EditorState.push(editorState, modifiedContent, 'change-inline-style'))
  }

  componentDidUpdate = (prevProps: RichTextEditorProps) => {
    if (prevProps?.value !== null && !this.state.isCursorOutsideTheEditor) {
      setTimeout(() => {
        this.editorRef.current?.focus()
      }, 200)
    }
  }

  componentDidMount() {
    document.addEventListener('click', (e) => {
      if (
        document
          .querySelector('#rich-editor-id')
          ?.parentElement?.contains((e.target as Node) || null)
      ) {
        this.setState({...this.state, isCursorOutsideTheEditor: false})
      } else {
        this.setState({...this.state, isCursorOutsideTheEditor: true})
      }
    })
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', () => {})
  }

  public render = () => {
    return (
      <>
        <div id='rich-editor-id' className={this.props.className}>
          {!this.props.hideControls && (
            <div
              className={clsx(
                'bg-body rounded-top border-bottom overflow-hidden position-relative',
                styles.controls
              )}
            >
              {BLOCK_STYLES.map((control) => (
                <RichTextEditorControlButton
                  key={control.style}
                  isIcon={control.isIcon}
                  onClick={this.toggleBlockStyle}
                  value={control.style}
                  active={this.isBlockStyleActive(control.style)}
                >
                  {control.label}
                </RichTextEditorControlButton>
              ))}
              {INLINE_STYLES.map((control) => (
                <RichTextEditorControlButton
                  key={control.style}
                  isIcon={control.isIcon}
                  onClick={this.toggleInlineStyle}
                  value={control.style}
                  active={this.isInlineStyleActive(control.style)}
                >
                  {control.label}
                </RichTextEditorControlButton>
              ))}
              <RichTextEditorControlButton isIcon onClick={this.editLink}>
                <MetronicIcon iconType='Text' iconName='Edit-text' />
              </RichTextEditorControlButton>
              {this.props.onUpload && (
                <RichTextEditorControlButton isIcon onClick={this.showFileList}>
                  <MetronicIcon iconType='Design' iconName='Image' />
                </RichTextEditorControlButton>
              )}
              {TEXT_ALIGN_STYLES.map((control) => (
                <RichTextEditorControlButton
                  key={control.style}
                  isIcon={control.isIcon}
                  onClick={this.applyAlignment}
                  value={control.style}
                  active={this.isInlineStyleActive(control.style)}
                >
                  {control.label}
                </RichTextEditorControlButton>
              ))}

              <RichTextEditorControlButton
                isPopOver
                isShowPopOver={this.state.modal.show}
                isIcon
                onClick={() => {
                  this.setState({
                    ...this.state,
                    modal: {show: !this.state.modal.show},
                  })
                }}
                onHidePopOver={() => {
                  this.setState({
                    ...this.state,
                    modal: {show: false},
                  })
                }}
                popOverChildren={
                  <TableGrid
                    handleSubmit={({rows, cols}) => this.insertTableFn({rows, columns: cols})}
                  />
                }
              >
                <MetronicIcon iconType='Layout' iconName='Layout-grid' />
              </RichTextEditorControlButton>
            </div>
          )}
          <div
            onClick={this.handleMouseDown}
            className={clsx(
              'form-control form-control-solid rounded-0 rounded-bottom',
              this.props.classes?.textArea
            )}
            dir={this.props.dir}
          >
            <Editor
              ref={this.editorRef}
              onChange={(editorState) => this.props.onChange(editorState)}
              editorState={this.getValue()}
              readOnly={this.props.readOnly}
              blockRendererFn={(block) => this.blockRendererFn(block, this.editorRef.current)}
              blockRenderMap={this.extendedBlockRenderMap}
              handleKeyCommand={(command, editorState) =>
                this.handleKeyCommand(command, editorState)
              }
              handleBeforeInput={this.handleBeforeInput}
              keyBindingFn={this.keyBindingFn}
              blockStyleFn={this.blockStyleFn}
            />
            <AlignmentTool />
          </div>
        </div>
        {this.state.linkModal.open && (
          <LinkModal
            open={this.state.linkModal.open}
            defaultUrl={this.state.linkModal.defaultUrl}
            onSubmit={(url) => {
              let editorState = this.getValue()
              let selection = editorState.getSelection()
              if (url === '') {
                const newEditorState = RichUtils.toggleLink(editorState, selection, null)
                this.props.onChange(newEditorState)
              } else {
                const contentState = editorState.getCurrentContent()
                const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {url})
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
                let newEditorState = EditorState.push(
                  editorState,
                  contentStateWithEntity,
                  'apply-entity'
                )
                newEditorState = RichUtils.toggleLink(newEditorState, selection, entityKey)
                this.props.onChange(newEditorState)
              }
              this.setState({linkModal: {open: false, defaultUrl: ''}})
            }}
            onClose={() => this.setState({linkModal: {open: false, defaultUrl: ''}})}
          />
        )}
      </>
    )
  }
}

const alignmentPlugin = createAlignmentPlugin()
const focusPlugin = createFocusPlugin()
const resizeablePlugin = createResizeablePlugin()
const blockDndPlugin = createBlockDndPlugin()
const imagePlugin = createImagePlugin({
  decorator: composeDecorators(
    resizeablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator
  ),
})

const {AlignmentTool} = alignmentPlugin

const BLOCK_STYLES = [
  {label: <MetronicIcon iconType='Text' iconName='H1' />, style: 'header-one', isIcon: true},
  {label: <MetronicIcon iconType='Text' iconName='H2' />, style: 'header-two', isIcon: true},
  {
    label: <MetronicIcon iconType='Text' iconName='Bullet-list' />,
    style: 'unordered-list-item',
    isIcon: true,
  },
  {label: <b>123</b>, style: 'ordered-list-item', isIcon: true},
]

const INLINE_STYLES = [
  {label: <MetronicIcon iconType='Text' iconName='Bold' />, style: 'BOLD', isIcon: true},
  {label: <MetronicIcon iconType='Text' iconName='Itallic' />, style: 'ITALIC', isIcon: true},
  {label: <MetronicIcon iconType='Text' iconName='Underline' />, style: 'UNDERLINE', isIcon: true},
]

const TEXT_ALIGN_STYLES = [
  {label: <MetronicIcon iconType='Text' iconName='Align-left' />, style: 'left', isIcon: true},
  {label: <MetronicIcon iconType='Text' iconName='Align-center' />, style: 'center', isIcon: true},
  {label: <MetronicIcon iconType='Text' iconName='Align-right' />, style: 'right', isIcon: true},
]
