import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { SweetAlert } from '../../../modals/SweetAlert'
import { Button } from '../../Button'

export interface LinkModalProps {
  open: boolean
  defaultUrl?: string
  onSubmit: (url: string) => void
  onClose: () => void
}

export const LinkModal = ({ open, defaultUrl = '', onSubmit, onClose }: LinkModalProps) => {
  const [url, setUrl] = useState(defaultUrl)

  useEffect(() => {
    if (open) {
      setUrl(defaultUrl)
    }
  }, [open, defaultUrl])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value)
  }, [])

  const handleSubmit = useCallback(() => {
    onSubmit(url)
  }, [url, onSubmit])

  return (
    <SweetAlert
      open={open}
      onClose={onClose}
      showConfirmButton={false}
      customClass={{ htmlContainer: 'overflow-visible' }}
    >
      <div className="text-start">
        <div className="mb-3">
          <label htmlFor="link-url" className="form-label">
            Link URL:
          </label>
          <input
            id="link-url"
            type="text"
            className="form-control"
            placeholder="Enter link URL (leave empty to remove link)"
            value={url}
            onChange={handleChange}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button className="me-3" onClick={onClose}>
            Back
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            OK
          </Button>
        </div>
      </div>
    </SweetAlert>
  )
}
